import { ArrowButton } from '@landing-blocks/shared/components/arrow-button/arrow-button.js';
import { BlockWrapper } from '@landing-blocks/shared/components/block-wrapper/block-wrapper.js';
import { HtmlText } from '@landing-blocks/shared/components/html-text/html-text.js';
import { MultilineText } from '@landing-blocks/shared/components/multiline-text/multiline-text.js';
import type { ComponentChildren, FunctionalComponent } from 'preact';
import { useRef } from 'preact/hooks';

import { cnBlockSlider1 } from './block-slider-1.constants.js';
import { useClickScroll } from './block-slider-1.hooks/use-click-scroll.js';
import { useDragScroll } from './block-slider-1.hooks/use-drag-scroll.js';
import { useLeftPadding } from './block-slider-1.hooks/use-left-padding.js';

import './block-slider-1.css';

type BlockAttributes = {
    title: string;
    children: ComponentChildren;
};

export interface BlockSlider1Props extends BlockAttributes {}

export const BlockSlider1: FunctionalComponent<BlockSlider1Props> = (props) => {
    const { title, children } = props;
    const wrapperRef = useRef<HTMLInputElement>(null);
    const itemsRef = useRef<HTMLInputElement>(null);

    useLeftPadding(wrapperRef, itemsRef);
    useDragScroll(itemsRef);
    const { handleScrollLeft, handleScrollRight } = useClickScroll(itemsRef);

    return (
        <div className={cnBlockSlider1({})}>
            <BlockWrapper ref={wrapperRef}>
                <MultilineText className={cnBlockSlider1('title')}>
                    <HtmlText html={title} />
                </MultilineText>
                <div className={cnBlockSlider1('arrows')}>
                    <ArrowButton onPointerDown={handleScrollLeft} />
                    <ArrowButton onPointerDown={handleScrollRight} right />
                </div>
            </BlockWrapper>
            <div className={cnBlockSlider1('items')} ref={itemsRef}>
                {children}
            </div>
        </div>
    );
};
